import React, { Component } from "react"
import image_url_map from "../../../static/images/constants/image_constants";
import classnames from "classnames"
import ReactCountryFlag from "react-country-flag"
import parse from "html-react-parser"
const amber_hi = image_url_map['amber_blue_bg'];
export class LanguageChange extends Component {
  state = {
    countries: {
      US: {
        btn_text: "English",
        text:
          " Hello!<br/> I am Amber, assistant to your CHRO and CEO. I was hired to make your organization an awesome place to work, but I cannot do that without your help. Did I reach out in a language you don't understand? Don't worry; I speak many languages. You can select the language you are most comfortable with. For all our future chats, you can choose a preferred language by reaching out to your HR team or simply <a href = 'mailto:help@infeedo.com'> raise a support ticket</a>.<br/> Speak to you soon! :)",
      },
      FR: {
        btn_text: "Français",
        text:
          " Bonjour !<br/> Je m'appelle Amber et je suis adjointe de votre DRH et de votre directeur général. J'ai été embauchée pour faire de votre organisation un endroit extraordinaire où travailler, mais je ne peux pas le faire sans votre aide. Est-ce que je m'adresse à vous dans une langue que vous ne comprenez pas ? Ne vous inquiétez pas, je parle beaucoup de langues. Vous pouvez choisir la langue dans laquelle vous êtes le plus à l'aise. Pour toutes nos discussions à venir, vous pouvez choisir votre langue préférée en consultant votre équipe RH ou simplement <a href = 'mailto:help@infeedo.com'>lever un ticket de support</a>.<br/> On se parle bientôt ! :)",
      },
      ID: {
        btn_text: "Indonesia",
        text:
          " Halo!<br/> Saya Amber, assistant CHRO dan CEO Anda. Saya bertugas membuat organisasi Anda menjadi tempat kerja yang mengagumkan, tetapi saya tak dapat melakukannya tanpa bantuan Anda. Apakah saya berbicara dalam bahasa yang Anda tak pahami? Jangan khawatir; Saya bicara banyak bahasa. Anda dapat memilih bahasa yang diinginkan. Untuk semua obrolan kita berikutnya, Anda dapat memilih bahasa yang diinginkan dengan menghubungi tim HR Anda atau <a href = 'mailto:help@infeedo.com'>naikkan tiket dukungan</a>.<br/> Mari segera berbincang! :)",
      },
      MY: {
        btn_text: "Bahasa Melayu",
        text:
          "Helo!<br/> Saya Amber, pembantu CHRO dan CEO anda. Saya ditugaskan untuk menjadikan organisasi anda sebagai tempat kerja yang hebat, tetapi saya tidak dapat melakukannya tanpa bantuan anda. Adakah saya membantu bahasa yang tidak anda fahami? Jangan bimbang; Saya bercakap banyak bahasa. Anda boleh memilih bahasa yang paling anda gemari. Untuk semua sembang masa akan datang kami, anda boleh memilih bahasa pilihan dengan menghubungi pasukan HR anda atau secara ringkas <a href = 'mailto:help@infeedo.com'> menaikkan tiket sokongan</a>.<br/> Bercakap dengan anda sebentar lagi! :) ",
      },
    },
    selected_country: "US",
  }

  changeCountry = async country => {
    this.setState({ selected_country: country })
  }

  render() {
    const { countries, selected_country } = this.state
    return (
      <div>
        <div className="lang-change-amber-img">
          <img src={amber_hi} alt="amber_hi" />
        </div>
        <div className="columns language-change-padding">
          <div className="column is-6 is-hidden-mobile">
            {Object.entries(countries).map(([key, properties], index) => (
              <button
                className={classnames(
                  "button  is-large mg-10 language-change-btn",
                  { "active-btn-shadow": selected_country === key }
                )}
                onClick={this.changeCountry.bind(this, key)}
              >
                <ReactCountryFlag countryCode={key} svg />
                <span className="language-change-btn-text">
                  {properties.btn_text}
                </span>
              </button>
            ))}
          </div>
          <div className="is-hidden-tablet">
            <label className="label is-size-7 has-text-weight-medium">
              Choose Language:
            </label>
            <div className="dropdown is-hoverable">
              <div className="dropdown-trigger">
                <button
                  className="button language-change-dropdown-trigger"
                  aria-haspopup="true"
                  aria-controls="dropdown-menu4"
                >
                  <ReactCountryFlag countryCode={selected_country} svg />
                  <span className="language-change-btn-text">
                    {countries[selected_country].btn_text
                      ? countries[selected_country].btn_text
                      : ""}
                  </span>
                  <div className="language-change-dropdown-pointer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path fill="#000000" d="M7,10L12,15L17,10H7Z" />
                    </svg>
                  </div>
                </button>
              </div>
              <div className="dropdown-menu" id="dropdown-menu4" role="menu">
                <div className="dropdown-content">
                  {Object.entries(countries).map(([key, properties], index) => (
                    <div
                      className="language-change-dropdown "
                      onClick={this.changeCountry.bind(this, key)}
                    >
                      <ReactCountryFlag countryCode={key} svg />
                      <span className="language-change-btn-text">
                        {properties.btn_text ? properties.btn_text : ""}{" "}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="column is-6 no-pd-left no-pd-right">
            <div className="mg-top-10">
              <span>{parse(countries[selected_country].text)}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LanguageChange
