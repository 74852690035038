import React, { Component } from "react"
import LanguageDemo from "../components/LanguageProject/LanguageDemo"
import SEOComponent from "../components/seo"
import "../css/bulma.css"
import "../css/index.css"

export class LanguageChange extends Component {

  render() {
    return (
      <div mode = {true} sticky_footer = {true}>
        <SEOComponent title="Amber by inFeedo | Language-Change" />
        <LanguageDemo />
      </div>
    )
    
  }
}

export default LanguageChange
